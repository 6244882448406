//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pendingNumber, statisticsNumber } from '@/api/modular/fileStream/documentManage'
export default {
    name: 'tab',
    data() {
        return {
            scale: 1, //
            tabMsg: {
                auditimgNum: {
                    route: {
                        path: '/fileStreamDocumentApproval',
                        query: {
                            index: 1,
                        },
                    },
                    text: '待審批公文',
                    img: require('@/assets/img/home/dsh.png'),
                    num: 0,
                    color: 'rgb(255, 114, 114)',
                    bg_color: 'rgba(255, 114, 114, 0.15)',
                },
                unReceivedNum: {
                    route: {
                        path: '/fileStreamDocumentAccept',
                        query: {
                            index: 1,
                        },
                    },
                    text: '待接收公文',
                    img: require('@/assets/img/home/dqs.png'),
                    num: 0,
                    color: 'rgb(23, 208, 177)',
                    bg_color: 'rgba(23, 208, 177, 0.15)',
                },
                auditedNum: {
                    route: {
                        path: '/fileStreamDocumentApproval',
                        query: {
                            index: 2,
                        },
                    },
                    text: '已審批公文',
                    img: require('@/assets/img/home/ysh.png'),
                    num: 0,
                    bg_color: 'rgba(120, 163, 255, 0.15)',
                    color: 'rgb(120, 163, 255)',
                },
                receivedNum: {
                    route: {
                        path: '/fileStreamDocumentAccept',
                        query: {
                            index: 2,
                        },
                    },
                    text: '已簽收公文',
                    img: require('@/assets/img/home/yqs.png'),
                    num: 0,
                    color: 'rgb(164, 85, 255)',
                    bg_color: 'rgba(164, 85, 255, 0.15)',
                },
                acceptSendNum: {
                    route: {
                        path: '/fileStreamArchived',
                        query: {
                            index: 0,
                        },
                    },
                    text: '文書歸檔',
                    img: require('@/assets/img/home/zsfs.png'),
                    num: 0,
                    color: 'rgb(255, 175, 54)',
                    bg_color: 'rgba(255, 175, 54, 0.15)',
                },
            },
        }
    },
    mounted() {
        this.getStatisticsNumber()
        this.initNumber()
        // window.addEventListener('resize', () => {
        //   this.scale = document.body.offsetWidth / 1920
        // })
    },
    methods: {
        getStatisticsNumber() {
            statisticsNumber()
                .then((res) => {
                    if (res.code === 200) {
                        const keys = Object.keys(res.data)
                        keys.forEach((element) => {
                            this.tabMsg[element].num = res.data[element]
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        initNumber() {
            pendingNumber()
                .then((res) => {
                    if (res.code === 200) {
                        this.tabMsg['auditimgNum'].num = res.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        toPath({ route }) {
            if (route) this.$router.push(route)
        },
    },
}
